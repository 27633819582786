import React from "react"
import { Link } from "gatsby"
import Swiper from "react-id-swiper"

import Layout from "../components/layout"
import joinTheTribeImage from "../images/join-the-tribe.png"
import { FooterAlternate2 } from "../components/footer"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />

    <div className="block block--grey how-it-works">
      <div className="container">
        <div className="block__header row">
          <div className="col-sm-offset-1 col-sm-10">
            <h1>Digital Sales Enablement</h1>
            <h2>Outsource your Sales & Marketing to the Tribe</h2>
          </div>
        </div>
        <div className="how-it-works__wrap row">
          <div className="how-it-works__svg container">
            <div className="how-it-works__svgwrap">
              <div className="hidden-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 1133 270"
                  width="1133"
                  height="270"
                  preserveAspectRatio="xMinYMin"
                >
                  <defs>
                    <clipPath id="landingMaskA">
                      <path d="M0 0h500v270H0zM500 0h133v30H500zM633 0h500v270H633zM500 240h133v30H500z" />
                    </clipPath>
                  </defs>
                  <g clipPath="url(#landingMaskA)">
                    <path
                      fill="none"
                      stroke="#e1e1e1"
                      strokeWidth="0"
                      strokeMiterlimit="10"
                      d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160"
                    />
                    <path
                      className="green"
                      fill="none"
                      stroke="#91c848"
                      strokeWidth="0"
                      strokeMiterlimit="10"
                      d="M155 135h160a50 50 0 0 0 50-50V55a50 50 0 0 1 50-50h110a40 30 0 0 1 40 30v200a40 30 0 0 0 40 30h110a50 50 0 0 0 50-50v-40a50 50 0 0 1 50-50h160"
                    />
                  </g>
                </svg>
              </div>
              <div className="visible-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 768 800"
                  width="768"
                  height="800"
                  preserveAspectRatio="xMinYMin"
                >
                  <path
                    fill="none"
                    stroke="#91c848"
                    strokeWidth="0"
                    strokeMiterlimit="10"
                    d="M500 100h300m0 225H409a25 25 0 0 0-25 25m0 220v40"
                  />
                </svg>
              </div>
            </div>
            <div className="how-it-works__svgwrap">
              <div className="hidden-xs"></div>
              <div className="visible-xs"></div>
            </div>
          </div>
          <div className="col-sm-12">
            <div className="row">
              <a
                target="_self"
                href="https://community.salestribe.com/"
                className="how-it-works__item how-it-works__side col-xs-12 col-sm-4"
              >
                <img
                  alt="SalesTribe"
                  src="media/svg/landing-256x183_sales-professionals.svg"
                />
                <h3 className="how-it-works__title">
                Join the Tribe and Level Up.
                </h3>
                <p className="how-it-works__caption">
                  SalesTribe works with sales people whom are now ready for
                  change
                </p>
                <button className="btn">Join for Free</button>
              </a>
              <div className="how-it-works__item middle col-xs-12 col-sm-4">
                <img
                  alt="SalesTribe"
                  src="/static/images/svg/landing-256x183_brand.svg"
                />
                <h3 className="how-it-works__title">
                  Accelerate via SalesTribe
                </h3>
                <p className="how-it-works__caption">
                  Providing sales people job readiness and connections to
                  suitable businesses
                </p>
              </div>
              <Link
                to="/how-it-works/start-businesses/"
                className="how-it-works__item how-it-works__side col-xs-12 col-sm-4"
              >
                <img
                  alt="SalesTribe"
                  src="media/svg/landing-256x183_startups.svg"
                />
                <h3 className="how-it-works__title">
                      Enable your sales & marketing teams​
                </h3>
                <p className="how-it-works__caption">
                  SalesTribe specialises in digital sales transformation
                </p>
                <button className="btn">How it works</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div className="block">
      <h2 style={{ textAlign: "center" }}>How we work with our clients​</h2>
      <div className="container">
        <div
          className="col-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              maxWidth: "950px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            src="/media/svg/landing-552x580_how_we_work_with_our_clients.svg"
          />
        </div>
      </div>
    </div>

    <div className="block">
      <h2 style={{ textAlign: "center" }}>Being part of the TRIBE helps you level-up.​ <br></br>
      Join for free and get access our modern sales & marketing programs.​</h2>
      <h3 style={{ textAlign: "center" }}>
        Join for free and get access to our{" "}
        <a href="https://apps.apple.com/us/app/id1529332994">iOS</a> and{" "}
        <a href="https://play.google.com/store/apps/details?id=com.mightybell.salestribe">
          Android
        </a>{" "}
        applications.
      </h3>
      <div className="container">
        <div
          className="col-12"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <img
            onClick={() =>
              window.open("https://community.salestribe.com/", "_self")
            }
            style={{
              maxWidth: "550px",
              width: "100%",
              height: "100%",
              cursor: "pointer",
            }}
            src={joinTheTribeImage}
            alt="Join the tribe for free"
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <h3>
           11 Being part of our TRIBE helps you to UPGRADE and PROTECT your sales
            career.
          </h3>
          <a
            href="https://community.salestribe.com/"
            target="_self"
            className="btn"
          >
            Find out more
          </a>
        </div>
      </div>
    </div>

  

    <div className="block block--base launchpad">
      <div className="container">
        <div className="block__header row">
          <div className="col-sm-offset-1 col-sm-10 col-md-offset-2 col-md-8">
            <h2>Understand the challenges now facing BUSINESSES.</h2>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <div className="launchpad__list row">
              <Link
                to="/knowledge-centre/challenges/b2b-sales-getting-harder/"
                className="launchpad__item col-sm-6 col-md-3"
              >
                <div className="launchpad__img">
                  <img
                    alt="SalesTribe"
                    src="media/svg/landing-256x183_man-running.svg"
                  />
                </div>
                <div className="launchpad__content">
                  <h4 className="launchpad__title alt">
                    <u>Challenge 1</u>
                  </h4>
                  <h3 className="launchpad__subject">
                    <strong>B2B sales is getting harder.....</strong>
                  </h3>
                  <p className="launchpad__caption">
                    63% of sales people are now failing to meet or exceed quota.
                    Are you performing?
                  </p>
                </div>
              </Link>
              <Link
                to="/knowledge-centre/challenges/i-need-to-become-a-specialist/"
                className="launchpad__item col-sm-6 col-md-3"
              >
                <div className="launchpad__img">
                  <img
                    alt="SalesTribe"
                    src="media/svg/landing-256x183_man-playful.svg"
                  />
                </div>
                <div className="launchpad__content">
                  <h4 className="launchpad__title alt">
                    <u>Challenge 2</u>
                  </h4>
                  <h3 className="launchpad__subject">
                    <strong>I need to become a specialist....</strong>
                  </h3>
                  <p className="launchpad__caption">
                    You can no longer add value to the buying process if you
                    have &#39;general&#39; knowledge.
                  </p>
                </div>
              </Link>
              <Link
                to="/knowledge-centre/challenges/social-selling-and-my-online-brand/"
                className="launchpad__item col-sm-6 col-md-3"
              >
                <div className="launchpad__img">
                  <img
                    alt="SalesTribe"
                    src="media/svg/landing-256x183_woman-laptop.svg"
                  />
                </div>
                <div className="launchpad__content">
                  <h4 className="launchpad__title alt">
                    <u>Challenge 3</u>
                  </h4>
                  <h3 className="launchpad__subject">
                    <strong>I need to build my personal brand......</strong>
                  </h3>
                  <p className="launchpad__caption">
                    Your personal brand is what people say about you when you
                    are not in the room.
                  </p>
                </div>
              </Link>
              <Link
                to="/knowledge-centre/challenges/i-want-make-career-change/"
                className="launchpad__item col-sm-6 col-md-3"
              >
                <div className="launchpad__img">
                  <img
                    alt="SalesTribe"
                    src="media/svg/landing-256x183_man-pensive.svg"
                  />
                </div>
                <div className="launchpad__content">
                  <h4 className="launchpad__title alt">
                    <u>Challenge 4</u>
                  </h4>
                  <h3 className="launchpad__subject">
                    <strong>I want to make a career change....</strong>
                  </h3>
                  <p className="launchpad__caption">
                    Your career now depends on how well you understand the new
                    realities of sales, and how well you leverage them to shape
                    your future.
                  </p>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* <div className="block block--base block--ruledtop"> */}
    {/*     <div className="container"> */}
    {/*         <div className="block__header row"> */}
    {/*             <div className="col-sm-12 col-md-offset-1 col-md-10"> */}
    {/*                 <h2>Don&#039;t miss...</h2> */}
    {/*             </div> */}
    {/*         </div> */}
    {/*         <div className="row"> */}
    {/*             <Link to="/events/salestribe-meet-melbourne/" className="upcoming__item col-sm-6 col-md-offset-3"> */}
    {/*                 <div className="row"> */}
    {/*                     <div className="card card--basic col-sm-offset-2 col-sm-8"> */}
    {/*                         <div className="card__inner"> */}
    {/*                             <h2 className="card__title">SalesTribe Meet-Up (Melbourne)</h2> */}
    {/*                             <h3 className="listing__location">27 February 2020</h3> */}
    {/*                             <p>Steps to Success in Buyer Journey Mapping</p> */}
    {/*                             <div className="card__buttons"> */}
    {/*                                 <button href="/events/salestribe-meet-melbourne/" className="btn btn-link">Learn more</button> */}
    {/*                             </div> */}
    {/*                         </div> */}
    {/*                     </div> */}
    {/*                 </div> */}
    {/*             </Link> */}
    {/*         </div> */}
    {/*     </div> */}
    {/* </div> */}

    {/* <div className="block block--base banner"> */}
    {/*     <div className="container"> */}
    {/*         <div className="row"> */}
    {/*             <div className="col-sm-12 col-md-offset-1 col-md-10"> */}
    {/*                 <h2 className="banner__title">Not sure where to start?</h2> */}
    {/*                 <div className="banner__buttons"> */}
    {/*                     <Link to="/enquiries/" className="btn btn-default">Contact an advisor</Link> */}
    {/*                 </div> */}
    {/*             </div> */}
    {/*         </div> */}
    {/*     </div> */}
    {/* </div> */}

    <div className="block block--base block--ruledtop slider">
      <div className="slider-container container">
        <Swiper
          className="slider"
          autoplay={{
            delay: 2000,
            disableOnInteraction: false,
          }}
          slidesPerView={5}
          loop
          breakpoints={{
            1080: {
              slidesPerView: 3,
            },
            700: {
              slidesPerView: 2,
            },
            400: {
              slidesPerView: 1,
            },
          }}
        >
           <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/aon.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/adecco.png"
            ></img>
          </div>
          {/* <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/arkadin.png"
            ></img>
          </div> */}
          <div  className="slider-image-ir">
            <img
              alt="SalesTribe"
              width="200px"
              src="../media/images/testimonial-logos/canon.png"
            ></img>
          </div>
       
          {/* <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/green-orbit.png"
            ></img>
          </div> */}
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/hilton.png"
            ></img>
          </div>
         
          {/* <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/lloyds-bank.png"
            ></img>
          </div> */}
         
          <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/nespresso.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/pwc.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/signify.png"
            ></img>
          </div>
          <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/spglobal.png"
            ></img>
          </div>
          <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/udemy.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/vocus.png"
            ></img>
          </div> 
          <div   className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/vodafone.png"
            ></img>
          </div>
           <div className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/monday.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/hubspot.png"
            ></img>
          </div>
          <div  className="slider-image">
            <img
              alt="SalesTribe"
              src="../media/images/testimonial-logos/deel.png"
            ></img>
          </div> 
        </Swiper>
      </div>
    </div>

    <div className="block block--grey related-items">
      <div className="block__header">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 col-md-offset-1 col-md-10">
              <h2>Knowledge centre</h2>
            </div>
          </div>
        </div>
      </div>
      <div className="related-items__wrap">
        <div className="container">
          <div className="row" data-tablet-slick>
            <article className="related-items__item col-sm-6 col-md-4">
              <Link
                to="/knowledge-centre/insights/7-laws-sales-success/"
                className="card card--article"
              >
                <div className="card__thumb">
                  <img
                    alt="article-ideas-announcement.jpg"
                    className="card__image"
                    height="404"
                    src="media/images/article-ideas-announcement_6oOJESq.2e16d0ba.fill-716x404.jpg"
                    width="716"
                  />
                  <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                  <header>
                    <p className="card__category">Sales careers</p>
                    <h3 className="card__title">
                      <strong>The 7 Laws of Sales Success</strong>
                    </h3>
                  </header>
                  <p>
                    There are certain &quot;Laws&quot; of the universe which
                    successful people often leverage, and today&#39;s&#39; sales
                    people must learn to embrace some of these important laws to
                    ensure future sales success.
                  </p>
                  <footer>
                    <p className="card__author">Graham Hawkins</p>
                  </footer>
                </div>
              </Link>
            </article>
            <article className="related-items__item col-sm-6 col-md-4">
              <Link
                to="/knowledge-centre/insights/sales-outreach-destroying-your-career/"
                className="card card--article"
              >
                <div className="card__thumb">
                  <img
                    alt="article-challenge-yell.jpg"
                    className="card__image"
                    height="404"
                    src="media/images/article-challenge-yell_YbkW7pV.2e16d0ba.fill-716x404.jpg"
                    width="716"
                  />
                  <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                  <header>
                    <p className="card__category">Sales careers</p>
                    <h3 className="card__title">
                      <strong>Is sales Outreach destroying your Career?</strong>
                    </h3>
                  </header>
                  <p>
                    If you are still performing 20th century outreach tactics
                    like &quot;cold calling&quot; then you are not just
                    destroying your own career, but you are also destroying your
                    employers brand and therefore their business.
                  </p>
                  <footer>
                    <p className="card__author">Graham Hawkins</p>
                  </footer>
                </div>
              </Link>
            </article>
            <article className="related-items__item col-sm-6 col-md-4">
              <Link
                to="/knowledge-centre/insights/sales-training-complete-waste-money-and-time-part-2/"
                className="card card--article"
              >
                <div className="card__thumb">
                  <img
                    alt="article-connect-coffee-chat.jpg"
                    className="card__image"
                    height="404"
                    src="media/images/article-connect-coffee-chat_xr8wJT.2e16d0ba.fill-716x404.jpg"
                    width="716"
                  />
                  <span className="card__image-meta">5 min read</span>
                </div>
                <div className="card__inner">
                  <header>
                    <p className="card__category">B2B Sales</p>
                    <h3 className="card__title">
                      <strong>
                        Sales Training’ is a waste of money and time (Part 2)
                      </strong>
                    </h3>
                  </header>
                  <p>
                    We must stop teaching sales people how to ‘push’ buyers, and
                    instead start training and coaching them on how to become
                    highly specialised teachers.
                  </p>
                  <footer>
                    <p className="card__author">Graham Hawkins</p>
                  </footer>
                </div>
              </Link>
            </article>
          </div>
          <div className="load-more">
            <Link to="/knowledge-centre/insights/" className="btn btn-link">
              Browse more insights
            </Link>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
